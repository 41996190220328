import React, { useCallback, useEffect, useRef } from "react";
import { DSButton, DSField, DSInput, DSModal } from "@hundred5/design-system";
import { useMedia } from "react-use";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

interface ConfirmModalProps {
  open: boolean;
  children: React.ReactNode;
  action: string;
  confirmation?: string;
  onConfirm: () => void;
  onClose: () => void;
  footerVariant?: "primary" | "destructive-primary" | "destructive-secondary";
  title?: string;
}

export function ConfirmModal({
  action,
  confirmation,
  children,
  onClose,
  onConfirm,
  open,
  title,
  footerVariant = "primary",
}: ConfirmModalProps) {
  const inputRef = useRef<HTMLInputElement>(null);
  const [confirmInputValue, setConfirmInputValue] = React.useState("");
  const isMobile = useMedia(device.tablet);

  const canConfirm =
    confirmation == null ||
    confirmInputValue.toLowerCase().trim() === confirmation.toLowerCase();

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setConfirmInputValue(event.target.value);
  };

  const handleSubmit = (event: React.FormEvent) => {
    event.preventDefault();
    event.stopPropagation();

    if (canConfirm) {
      onConfirm();
      setConfirmInputValue("");
    }
  };

  const handleClose = useCallback(() => {
    setConfirmInputValue("");
    onClose();
  }, [setConfirmInputValue, onClose]);

  useEffect(() => {
    if (open && isMobile) {
      window.scroll({ top: window.innerHeight });
    }
  }, [open, isMobile]);

  return (
    <DSModal fluid open={open} onClose={handleClose}>
      <Container data-testid="confirm-modal">
        {title && (
          <Header>
            <Title>{title}</Title>
            <DSModal.Separator />
          </Header>
        )}
        {!isMobile && <DSModal.CloseButton fixed onClick={handleClose} small />}
        <form onSubmit={handleSubmit}>
          <Content>{children}</Content>
          {confirmation != null ? (
            <DSField
              for="confirm-modal-input"
              label={
                <span>
                  Type{" "}
                  <span className="notranslate">
                    {confirmation.toUpperCase()}{" "}
                  </span>
                  to confirm:
                </span>
              }
            >
              <Input
                ref={inputRef}
                id="confirm-modal-input"
                value={confirmInputValue}
                onChange={handleChange}
                autoFocus
              />{" "}
            </DSField>
          ) : null}
          <Footer float="right">
            <DSButton variant="secondary" type="button" onClick={handleClose}>
              Cancel
            </DSButton>
            <DSButton
              data-testid="confirm-modal-action-button"
              type="submit"
              disabled={!canConfirm}
              variant={footerVariant}
            >
              {action}
            </DSButton>
          </Footer>
        </form>
      </Container>
    </DSModal>
  );
}

export const Container = styled.div`
  max-width: 568px;
`;

export const Content = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-size: 14px;
  padding-bottom: 16px;
`;

export const Footer = styled(DSModal.Footer)`
  display: flex;
  gap: 8px;

  @media all and (max-width: 768px) {
    flex-direction: column-reverse;
  }
`;

export const Header = styled.header`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-size: 16px;
  font-weight: 700;
  margin-bottom: 16px;

  @media all and (max-width: 768px) {
    padding-top: 8px;
  }
`;

export const Input = styled(DSInput)`
  width: 100%;

  @media all and (min-width: 769px) {
    width: 280px;
  }
`;

export const Title = styled.h2`
  font-size: 16px;
  line-height: 19px;
  margin: 0;
  padding-bottom: 16px;
`;
