import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { useMedia } from "react-use";
import { TogglHireLogo } from "@/features/common";
import {
  ProfileFooter,
  ProfileGeneralDetails,
  ProfileCTA,
  ProfileSkillAssessments,
} from "@/features/profile";
import { css } from "@emotion/react";
import { SkillProfileProgress } from "@/features/skill";
import { JobOfferPreferences } from "@/features/job-offer";

interface ProfileProps {
  slug: string;
  editable: boolean;
}

export function Profile({ slug, editable }: ProfileProps) {
  const isTablet = useMedia(device.tablet);

  return (
    <ProfileContainer editable={editable}>
      {!editable && (
        <Logo>
          <a href="https://toggl.com/hire" target="_blank" rel="noreferrer">
            <TogglHireLogo type="logo" width={isTablet ? 90 : 188} />
          </a>
        </Logo>
      )}

      <ProfileContent editable={editable}>
        {editable && (
          <SkillProfileProgressContainer>
            <SkillProfileProgress />
          </SkillProfileProgressContainer>
        )}

        <ProfileGeneralDetails slug={slug} editable={editable} />

        {editable && <JobOfferPreferences />}

        <ProfileSkillAssessments slug={slug} editable={editable} />
      </ProfileContent>

      {!editable && <ProfileCTA />}
      {!editable && <ProfileFooter />}
    </ProfileContainer>
  );
}

const ProfileContainer = styled.div<{ editable: boolean }>`
  position: relative;

  ${(props) =>
    !props.editable &&
    css`
      background-color: ${props.theme.colors.peach[20]};
      min-height: var(--view-height);
    `}
`;

const Logo = styled.div`
  padding: 24px 36px;
  display: flex;

  & > a {
    display: flex;
  }

  @media ${device.tablet} {
    padding: 24px 16px 8px;
  }
`;

const ProfileContent = styled.div<{ editable: boolean }>`
  display: flex;
  flex-direction: column;
  gap: 32px;
  width: 100%;
  max-width: 740px;
  margin: 0 auto;
  padding-bottom: ${(props) => (props.editable ? "24px" : "204px")};
  padding-top: 24px;

  @media ${device.tablet} {
    padding-bottom: ${(props) => (props.editable ? "16px" : "78px")};
    padding-top: 16px;
  }
`;

const SkillProfileProgressContainer = styled.div`
  padding: 16px;
  border-radius: 8px;
  background-color: ${(props) => props.theme.colors.white};

  @media ${device.tablet} {
    border-radius: unset;
  }
`;
