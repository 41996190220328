import { string, object } from "yup";
import { Candidate, PublicProfileSettings } from "@/generated/graphql";
import { TProfileGeneralDetailsForm } from "./profile-general-details-edit.types";

export function getInitialValues(
  publicProfile?: Partial<Candidate>,
  publicProfileSettings?: Partial<PublicProfileSettings> | null
): TProfileGeneralDetailsForm {
  return {
    publicProfile: {
      firstName: publicProfile?.firstName || "",
      lastName: publicProfile?.lastName || "",
      email: publicProfile?.email || "",
      linkedin: publicProfile?.linkedin || "",
      github: publicProfile?.github || "",
      phone: publicProfile?.phone || "",
      city: publicProfile?.city || "",
      country: publicProfile?.country || "",
    },
    publicProfileSettings: {
      showEmail: !!publicProfileSettings?.showEmail,
      showLinkedinURL: !!publicProfileSettings?.showLinkedinURL,
      showGithubURL: !!publicProfileSettings?.showGithubURL,
      showPhoneNumber: !!publicProfileSettings?.showPhoneNumber,
      showCity: !!publicProfileSettings?.showCity,
      showCountry: !!publicProfileSettings?.showCountry,
    },
  };
}

export function getValidationSchema() {
  return object().shape({
    publicProfile: object().shape({
      firstName: string().trim().required("This field is mandatory"),
      lastName: string().trim().required("This field is mandatory"),
      city: string().trim(),
      country: string().trim(),
      phone: string().trim(),
      email: string()
        .trim()
        .required("This field is mandataory")
        .email("Enter a valid email"),
      linkedin: string()
        .trim()
        .matches(
          /^https:\/\/([a-z]{2,3}\.)?linkedin\.com/,
          "Invalid URL format. Example: https://www.linkedin.com/in/user-name-123"
        ),
      github: string()
        .trim()
        .matches(/^https:\/\/([a-z]{2,3}\.)?github\.com/, "Invalid URL format"),
    }),
  });
}
