import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { ProgressBlocks } from "@/features/common";

interface SkillAssessmentProps {
  name: string;
  score: number;
}

export function SkillAssessment({ name, score }: SkillAssessmentProps) {
  return (
    <SkillAssessmentContainer>
      <Name data-recording-sensitive>{name}</Name>
      <Progress>
        <ProgressBlocks value={score} color="orange-100" />
        <Score>{score}%</Score>
      </Progress>
    </SkillAssessmentContainer>
  );
}

const SkillAssessmentContainer = styled.div`
  display: grid;
  grid-template-areas: "name progress";
  grid-auto-columns: minmax(30%, 40%) minmax(70%, 60%);
  gap: 12px;
  align-items: center;

  @media ${device.tablet} {
    grid-template-areas: "name" "progress";
    grid-auto-columns: 100%;
  }
`;

const Name = styled.div`
  grid-area: name;
  color: ${(props) => props.theme.typography.colorPrimary};
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
`;

const Progress = styled.div`
  grid-area: progress;
  display: flex;
  align-items: center;
`;

const Score = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 500;
  font-size: 16px;
  line-height: 20px;
  text-align: end;
  min-width: 54px;
  max-width: 64px;
  flex: 1;

  @media ${device.tablet} {
    max-width: 60px;
  }
`;
