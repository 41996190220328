import React from "react";
import { DSModal } from "@hundred5/design-system";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { ProfileSkillAssessmentsEdit } from "@/features/profile";

interface ProfileSkillAssessmentsEditModalProps {
  open: boolean;
  onClose: () => void;
}

export function ProfileSkillAssessmentsEditModal({
  open,
  onClose,
}: ProfileSkillAssessmentsEditModalProps) {
  return (
    <DSModal open={open} onClose={onClose} contentStyle={{ padding: 0 }}>
      <Header>
        <CancelButton onClick={onClose}>Cancel</CancelButton>
        Skill Profile
        <CloseButton>
          <DSModal.CloseButton fixed small onClick={onClose} />
        </CloseButton>
      </Header>
      <Separator />
      <Content>
        <ProfileSkillAssessmentsEdit onSaveSuccess={onClose} />
      </Content>
    </DSModal>
  );
}

const Header = styled.div`
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorPrimary};
  align-items: flex-start;
  padding: 36px 36px 16px;
  display: flex;

  @media ${device.tablet} {
    padding: 24px 16px 16px;
    width: 100%;
    display: inline-grid;
    justify-content: center;
    align-items: center;
    text-align: center;
    grid-template-columns: 1fr 1fr 1fr;
  }
`;

const CancelButton = styled.button`
  all: unset;
  font-weight: 500;
  font-size: 14px;
  cursor: pointer;
  display: none;

  @media ${device.tablet} {
    display: flex;
  }
`;

const CloseButton = styled.span`
  @media ${device.tablet} {
    display: none;
  }
`;

const Separator = styled.div`
  height: 1px;
  background-color: ${(props) => props.theme.colors.purple[10]};
`;

const Content = styled.div`
  padding: 36px;
  max-height: calc(var(--view-height) - 200px);
  overflow-y: auto;

  @media ${device.tablet} {
    padding: 16px;
    max-height: calc(var(--view-height) - 120px);
  }
`;
