import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

export function ProfileFooter() {
  return (
    <ProfileFooterContainer>
      <Content>
        <LinkContent>
          <LinkList>
            <Link href="https://toggl.com/legal/hire/terms" target="_blank">
              Terms of Service
            </Link>
            <Link href="https://toggl.com/legal/hire/privacy/" target="_blank">
              Privacy Policy
            </Link>
          </LinkList>
          <LinkList>
            <Link href="https://www.instagram.com/togglhire" target="_blank">
              Instagram
            </Link>
            <Link
              href="https://www.linkedin.com/company/togglhire"
              target="_blank"
            >
              LinkedIn
            </Link>
            <Link href="https://twitter.com/togglhire" target="_blank">
              Twitter
            </Link>
          </LinkList>
        </LinkContent>
        <CopyrightInfo>© {new Date().getFullYear()} Toggl Hire</CopyrightInfo>
      </Content>
    </ProfileFooterContainer>
  );
}

const ProfileFooterContainer = styled.div`
  background-color: ${(props) => props.theme.colors.purple[100]};
  padding: 60px;

  @media ${device.tablet} {
    padding: 24px;
  }
`;

const Content = styled.div`
  max-width: 980px;
  margin: auto;
`;

const LinkContent = styled.div`
  display: flex;
  justify-content: space-between;

  @media ${device.tablet} {
    flex-direction: column;
    gap: 32px;
  }
`;

const LinkList = styled.div`
  display: flex;
  gap: 32px;

  @media ${device.tablet} {
    flex-direction: column;
    gap: 16px;
  }
`;

const Link = styled.a`
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  color: ${(props) => props.theme.colors.white};
  opacity: 0.7;

  &:hover {
    opacity: 1;
    font-style: italic;
  }
`;

const CopyrightInfo = styled.div`
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 400;
  font-size: 13px;
  color: ${(props) => props.theme.colors.white};
  opacity: 0.5;
  margin-top: 44px;
`;
