import React from "react";
import styled from "@emotion/styled";

interface EmptySkillAssessmentListProps {
  editable: boolean;
  userName?: string;
}

export function EmptySkillAssessmentList({
  editable,
  userName,
}: EmptySkillAssessmentListProps) {
  return (
    <EmptySkillAssessmentListContainer>
      {editable
        ? "You have not added any skills to your public profile yet."
        : `${
            userName || "This user"
          } has not added any skills to their public profile yet.`}
    </EmptySkillAssessmentListContainer>
  );
}

const EmptySkillAssessmentListContainer = styled.div`
  background: ${(props) => props.theme.colors.peach[50]};
  border-radius: 8px;
  font-weight: 500;
  font-size: 16px;
  color: ${(props) => props.theme.typography.colorSecondary};
  display: flex;
  justify-content: center;
  padding: 6px 16px;
  margin: 74px auto 0px;
  width: fit-content;
`;
