import { useEffect } from "react";
import { useLoggedIn } from "@/features/auth";
import { useApiErrorHandler } from "@/features/error";
import { useValidateProfileQuery } from "@/generated/graphql";

export const useValidateProfile = (slug: string) => {
  const loggedIn = useLoggedIn();
  const [{ data, fetching, stale, error }] = useValidateProfileQuery({
    variables: { slug, loggedIn: !!loggedIn },
  });
  const handleError = useApiErrorHandler();

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  return {
    loading: loggedIn === null || fetching || stale,
    /**
     * Public profile exists if public profile query returns data
     */
    exists: !!data?.publicProfile?.id,
    /**
     * Check if user is logged in and is the owner of the profile
     */
    isMine: slug === data?.me?.slug,
  };
};
