import React from "react";
import styled from "@emotion/styled";
import { CopyClipboardButton, NavigationHeader } from "@/features/common";
import { useAmplitude } from "@/features/analytics";
import { ProfileNavigationHeaderContextMenu } from "../profile-navigation-header-context-menu/profile-navigation-header-context-menu";

interface ProfileNavigationHeaderProps {
  slug: string;
}

export function ProfileNavigationHeader({
  slug,
}: ProfileNavigationHeaderProps) {
  const { logEvent } = useAmplitude();

  return (
    <NavigationHeader>
      My Profile
      <ActionContent>
        <CopyClipboardButton
          tooltip="Copy profile URL"
          linkToCopy={`${process.env.NEXT_PUBLIC_APP_URL}/${slug}`}
          onClick={() => logEvent("profile/copied profile link")}
        />
        <ProfileNavigationHeaderContextMenu />
      </ActionContent>
    </NavigationHeader>
  );
}

const ActionContent = styled.div`
  margin-left: auto;
  display: flex;
  flex-direction: row;
  gap: 12px;
  align-items: center;
  justify-content: center;
  align-content: center;
`;
