import React, { useEffect } from "react";
import styled from "@emotion/styled";
import { Formik, Form } from "formik";
import { DSButton } from "@hundred5/design-system";
import {
  useProfileGeneralDetailsEditQuery,
  useUpdateProfileGeneralDetailsSettingsMutation,
  useUpdateProfileGeneralDetailsMutation,
} from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { useAmplitude } from "@/features/analytics";
import {
  FormikInput,
  FormikSelect,
  VisibilityButton,
  useCountryList,
  useFlashMessages,
} from "@/features/common";
import {
  getInitialValues,
  getValidationSchema,
} from "./profile-general-details-edit.utils";
import { TProfileGeneralDetailsForm } from "./profile-general-details-edit.types";

interface ProfileGeneralDetailsEditProps {
  onSave?: () => void;
}

export function ProfileGeneralDetailsEdit({
  onSave,
}: ProfileGeneralDetailsEditProps) {
  const handleError = useApiErrorHandler();
  const { addMessage } = useFlashMessages();
  const { logEvent } = useAmplitude();

  const { countries } = useCountryList();

  const [{ data, error }] = useProfileGeneralDetailsEditQuery();

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  const [, updateContactInfoSettings] =
    useUpdateProfileGeneralDetailsSettingsMutation();
  const [, updateProfile] = useUpdateProfileGeneralDetailsMutation();

  const publicProfile = data?.me;
  const publicProfileSettings = data?.publicProfileSettings;

  const submitPublicProfile = async (values: TProfileGeneralDetailsForm) => {
    const result = await updateProfile({
      firstName: values.publicProfile.firstName,
      lastName: values.publicProfile.lastName,
      linkedin: values.publicProfile.linkedin,
      github: values.publicProfile.github,
      phone: values.publicProfile.phone,
      country: values.publicProfile.country,
      city: values.publicProfile.city,
    });

    if (result.error) {
      handleError(result.error);
    }

    const resultSettings = await updateContactInfoSettings({
      settings: values.publicProfileSettings,
    });

    if (resultSettings.error) {
      handleError(resultSettings.error);
    }

    if (!resultSettings.error && !result.error) {
      addMessage({ type: "save_success" });
      logEvent("profile/save", { section: "general details" });

      const editedContactData =
        publicProfile?.linkedin !== result.data?.updateProfile.linkedin ||
        publicProfile?.github !== result.data?.updateProfile.github ||
        publicProfile?.phone !== result.data?.updateProfile.phone;

      const editedVisibilityContactData =
        publicProfileSettings?.showEmail !=
          resultSettings.data?.updateContactInfoSettings?.showEmail ||
        publicProfileSettings?.showGithubURL !=
          resultSettings.data?.updateContactInfoSettings?.showGithubURL ||
        publicProfileSettings?.showPhoneNumber !=
          resultSettings.data?.updateContactInfoSettings?.showPhoneNumber;

      if (editedContactData || editedVisibilityContactData) {
        logEvent("profile/edit contact details", {
          section: "contact general details ",
        });
      }
      onSave?.();
    }
  };

  return (
    <Formik
      onSubmit={submitPublicProfile}
      initialValues={getInitialValues(publicProfile, publicProfileSettings)}
      validationSchema={getValidationSchema()}
      enableReinitialize={true}
    >
      {({ dirty, isSubmitting, values, setFieldValue }) => (
        <Form data-recording-ignore="mask">
          <ItemContainer>
            <InputWrapper>
              <FormikInput
                name="publicProfile.firstName"
                label="First name"
                required
              />
            </InputWrapper>
          </ItemContainer>
          <ItemContainer>
            <InputWrapper>
              <FormikInput
                name="publicProfile.lastName"
                label="Last name"
                required
              />
            </InputWrapper>
          </ItemContainer>
          <ItemContainer>
            <InputWrapper isVisibile={values.publicProfileSettings.showEmail}>
              <FormikInput
                name="publicProfile.email"
                label="Email"
                required
                disabled
              />
            </InputWrapper>
            <VisibilityButtonContainer>
              <VisibilityButton
                value={values.publicProfileSettings.showEmail}
                onClick={() => {
                  setFieldValue(
                    "publicProfileSettings.showEmail",
                    !values.publicProfileSettings.showEmail
                  );
                }}
              />
            </VisibilityButtonContainer>
          </ItemContainer>
          <ItemContainer>
            <InputWrapper
              isVisibile={values.publicProfileSettings.showLinkedinURL}
            >
              <FormikInput
                name="publicProfile.linkedin"
                label="LinkedIn profile link"
              />
            </InputWrapper>
            <VisibilityButtonContainer>
              <VisibilityButton
                value={values.publicProfileSettings.showLinkedinURL}
                onClick={() => {
                  setFieldValue(
                    "publicProfileSettings.showLinkedinURL",
                    !values.publicProfileSettings.showLinkedinURL
                  );
                }}
              />
            </VisibilityButtonContainer>
          </ItemContainer>
          <ItemContainer>
            <InputWrapper
              isVisibile={values.publicProfileSettings.showGithubURL}
            >
              <FormikInput
                name="publicProfile.github"
                label="GitHub profile link"
              />
            </InputWrapper>
            <VisibilityButtonContainer>
              <VisibilityButton
                value={values.publicProfileSettings.showGithubURL}
                onClick={() => {
                  setFieldValue(
                    "publicProfileSettings.showGithubURL",
                    !values.publicProfileSettings.showGithubURL
                  );
                }}
              />
            </VisibilityButtonContainer>
          </ItemContainer>
          <ItemContainer>
            <InputWrapper
              isVisibile={values.publicProfileSettings.showPhoneNumber}
            >
              <FormikInput name="publicProfile.phone" label="Phone number" />
            </InputWrapper>
            <VisibilityButtonContainer>
              <VisibilityButton
                value={values.publicProfileSettings.showPhoneNumber}
                onClick={() => {
                  setFieldValue(
                    "publicProfileSettings.showPhoneNumber",
                    !values.publicProfileSettings.showPhoneNumber
                  );
                }}
              />
            </VisibilityButtonContainer>
          </ItemContainer>
          <ItemContainer>
            <InputWrapper isVisibile={values.publicProfileSettings.showCity}>
              <FormikInput name="publicProfile.city" label="City" />
            </InputWrapper>
            <VisibilityButtonContainer>
              <VisibilityButton
                value={values.publicProfileSettings.showCity}
                onClick={() => {
                  setFieldValue(
                    "publicProfileSettings.showCity",
                    !values.publicProfileSettings.showCity
                  );
                }}
              />
            </VisibilityButtonContainer>
          </ItemContainer>
          <ItemContainer>
            <InputWrapper isVisibile={values.publicProfileSettings.showCountry}>
              <FormikSelect
                name="publicProfile.country"
                label="Country"
                items={countries.map((country) => ({
                  id: country.name,
                  label: country.name,
                  labelText: country.name,
                }))}
              />
            </InputWrapper>
            <VisibilityButtonContainer>
              <VisibilityButton
                value={values.publicProfileSettings.showCountry}
                onClick={() => {
                  setFieldValue(
                    "publicProfileSettings.showCountry",
                    !values.publicProfileSettings.showCountry
                  );
                }}
              />
            </VisibilityButtonContainer>
          </ItemContainer>

          <Footer>
            <DSButton type="submit" disabled={!dirty || isSubmitting}>
              Save
            </DSButton>
          </Footer>
        </Form>
      )}
    </Formik>
  );
}

const ItemContainer = styled.div`
  width: 100%;
  display: flex;
  flex: 0 0 100%;
  gap: 8px;
  margin-bottom: 12px;
`;

const InputWrapper = styled.div<{ isVisibile?: boolean }>`
  width: calc(100% - 44px);
  opacity: ${(props) =>
    props.isVisibile || props.isVisibile === undefined ? 1 : 0.4};
`;

const VisibilityButtonContainer = styled.div`
  margin-top: 19px;
`;

const Footer = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: right;
`;
