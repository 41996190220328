import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";
import { isEqual } from "lodash";
import { DSButton } from "@hundred5/design-system";
import {
  useProfileSkillAssessmentsEditQuery,
  useUpdateProfileSkillIDsToDisplayMutation,
} from "@/generated/graphql";
import { useAmplitude } from "@/features/analytics";
import { useApiErrorHandler } from "@/features/error";
import {
  Loader,
  ProgressBar,
  VisibilityButton,
  useFlashMessages,
} from "@/features/common";

interface ProfileSkillAssessmentsEditProps {
  onSaveSuccess?: () => void;
}

export function ProfileSkillAssessmentsEdit({
  onSaveSuccess,
}: ProfileSkillAssessmentsEditProps) {
  const handleError = useApiErrorHandler();
  const { addMessage } = useFlashMessages();
  const { logEvent } = useAmplitude();
  const [originalSkillIDs, setOriginalSkillIDs] = useState<string[]>([]);
  const [updatedSkillIDs, setUpdatedSkillIDs] = useState<string[]>([]);

  const [{ data, fetching, error }] = useProfileSkillAssessmentsEditQuery();
  const [{ fetching: updating }, updateSkillIDsToDisplay] =
    useUpdateProfileSkillIDsToDisplayMutation();

  useEffect(() => {
    setOriginalSkillIDs(data?.publicProfileSettings?.skillIDsToDisplay || []);
    setUpdatedSkillIDs(data?.publicProfileSettings?.skillIDsToDisplay || []);
  }, [
    setOriginalSkillIDs,
    setUpdatedSkillIDs,
    data?.publicProfileSettings?.skillIDsToDisplay,
  ]);

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  const handleVisibilityChange = (skillId: string, added: boolean) => {
    if (added && !updatedSkillIDs.includes(skillId)) {
      setUpdatedSkillIDs([...updatedSkillIDs, skillId]);
    }
    if (!added && !!updatedSkillIDs.includes(skillId)) {
      setUpdatedSkillIDs(updatedSkillIDs.filter((item) => item !== skillId));
    }
  };

  const handleSave = async () => {
    const result = await updateSkillIDsToDisplay({ ids: updatedSkillIDs });

    if (result.error) {
      handleError(result.error);
    } else {
      setOriginalSkillIDs(updatedSkillIDs);
      addMessage({ type: "save_success" });
      logEvent("profile/save", { section: "skills" });

      if (
        result.data?.updateSkillIDsToDisplay?.skillIDsToDisplay &&
        data?.publicProfileSettings?.skillIDsToDisplay &&
        result.data?.updateSkillIDsToDisplay?.skillIDsToDisplay.length <
          data?.publicProfileSettings?.skillIDsToDisplay.length
      ) {
        logEvent("profile/disabled skills in public profile", {
          section: "skills",
        });
      }
      onSaveSuccess?.();
    }
  };

  const hasChanges = !isEqual(originalSkillIDs.sort(), updatedSkillIDs.sort());
  const isSkillEnabled = (skillId: string) =>
    updatedSkillIDs?.includes(skillId);

  return (
    <>
      <SkillList>
        {fetching ? (
          <Loader absolute />
        ) : (
          data?.me.skills?.map((skill) => (
            <SkillListItem key={skill.id}>
              <Name
                data-recording-sensitive
                disabled={!isSkillEnabled(skill.id)}
              >
                {skill.name}
              </Name>
              <Progress disabled={!isSkillEnabled(skill.id)}>
                <ProgressBar value={skill.score} color="orange-100" />
                <Score>{skill.score}%</Score>
              </Progress>
              <Questions disabled={!isSkillEnabled(skill.id)}>
                {skill.questionCount} questions
              </Questions>
              <Action>
                <VisibilityButton
                  disabled={!skill.isAssessed && skill.questionCount < 10}
                  disabledTooltip="Complete skill assessment or answer at least 10 questions to add this skill to your skill profile"
                  value={isSkillEnabled(skill.id)}
                  onClick={(newValue) =>
                    handleVisibilityChange(skill.id, newValue)
                  }
                />
              </Action>
            </SkillListItem>
          ))
        )}
      </SkillList>
      <Footer>
        <DSButton disabled={!hasChanges || updating} onClick={handleSave}>
          Save
        </DSButton>
      </Footer>
    </>
  );
}

const SkillList = styled.div`
  position: relative;
  min-height: 104px;
`;

const SkillListItem = styled.div`
  display: grid;
  grid-template-areas: "name progress questions action";
  grid-template-columns: repeat(3, minmax(0, 1fr)) 100px;
  grid-auto-rows: min-content;
  grid-column-gap: 16px;
  grid-row-gap: 4px;
  align-content: center;
  padding: 16px 16px 16px 0;
  border-bottom: 1px solid ${(props) => props.theme.colors.purple[10]};

  &:last-of-type {
    border-bottom: none;
  }

  @media ${device.tablet} {
    grid-template-areas: "name action" "questions action" "progress action";
    grid-template-columns: minmax(0, 2fr) minmax(0, 1fr);
    font-size: 12px;
  }
`;

const Name = styled.div<{ disabled: boolean }>`
  grid-area: name;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};
`;

const Progress = styled.div<{ disabled: boolean }>`
  grid-area: progress;
  display: flex;
  align-items: center;
  gap: 12px;
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};

  @media ${device.tablet} {
    gap: 8px;
  }
`;

const Score = styled.div`
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorPrimary};
  min-width: 36px;
  text-align: end;
`;

const Questions = styled.div<{ disabled: boolean }>`
  grid-area: questions;
  justify-self: flex-end;
  align-self: center;
  font-weight: 400;
  font-size: 14px;
  color: ${(props) => props.theme.typography.colorSecondary};
  opacity: ${(props) => (props.disabled ? "0.4" : "1")};

  @media ${device.tablet} {
    justify-self: flex-start;
  }
`;

const Action = styled.div`
  grid-area: action;
  align-self: center;
  justify-self: flex-end;
  text-align: end;
  margin: -8px 0;
`;

const Footer = styled.div`
  margin-top: 36px;
  display: flex;
  justify-content: right;
`;
