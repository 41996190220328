import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import {
  useDeleteProfilePictureMutation,
  useProfilePictureQuery,
  useStartProfilePictureUploadMutation,
} from "@/generated/graphql";
import { useAmplitude } from "@/features/analytics";
import { useApiErrorHandler } from "@/features/error";
import { useFlashMessages } from "@/features/common";
import { recordTransaction } from "@/features/common/utils/tracing";
import { EditContextMenu } from "./ui";
import { useLoggedIn } from "@/features/auth";

const MAX_SIZE_MB = 5;

interface ProfilePictureProps {
  slug: string;
  editable: boolean;
}

export function ProfilePicture({ slug, editable }: ProfilePictureProps) {
  const loggedIn = useLoggedIn();
  const handleError = useApiErrorHandler();
  const { addMessage } = useFlashMessages();
  const { logEvent } = useAmplitude();
  const [pictureUrl, setPictureUrl] = useState<string | undefined>(undefined);
  const [uploading, setUploading] = useState(false);

  const [{ fetching: deleting }, deleteProfilePicture] =
    useDeleteProfilePictureMutation();

  const [, startProfilePictureUpload] = useStartProfilePictureUploadMutation();

  const [{ data, fetching: loading, error }] = useProfilePictureQuery({
    variables: { editable, slug },
    /**
     * Photo is uploaded in Cloudinary directly and
     * we can't update urql cache with result received from that separate REST request,
     * so in order not to have stale data shown from cache, we have to force network request
     */
    requestPolicy: "network-only",
    pause: !loggedIn,
  });

  useEffect(() => {
    setPictureUrl(
      data?.me?.profilePictureURL ||
        data?.publicProfile?.profilePictureURL ||
        undefined
    );
  }, [data]);

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  const handleDelete = async () => {
    const result = await deleteProfilePicture({});

    if (result.error) {
      handleError(result.error);
    } else {
      addMessage({ type: "save_success" });
      logEvent("profile/save", { section: "picture delete" });
    }
  };

  const handleUpload = async (file: File) => {
    setUploading(true);
    const result = await startProfilePictureUpload({});

    if (result.error) {
      handleError(result.error);
      setUploading(false);
      return;
    }

    const uploadUrl = result.data?.startProfilePictureUpload?.url;
    if (!uploadUrl) {
      addMessage({ type: "picture_upload_error", error: "Missing upload URL" });
      setUploading(false);
      return;
    }

    recordTransaction("Upload profile picture", async () => {
      const formData = new FormData();
      formData.append("file", file);

      try {
        const uploadResult = await fetch(uploadUrl, {
          method: "POST",
          body: formData,
        });

        if (!uploadResult.ok) {
          throw new Error(`Upload status: ${uploadResult.statusText}`);
        }

        uploadResult.json().then((data) => {
          setPictureUrl(data.url);
        });

        addMessage({ type: "save_success" });
        logEvent("profile/save", { section: "picture upload" });
      } catch (error: any) {
        addMessage({ type: "picture_upload_error", error: error.message });
      } finally {
        setUploading(false);
      }
    });
  };

  return (
    <ProfilePictureContainer>
      {!!pictureUrl ? (
        <Img src={pictureUrl} alt="Profile picture" draggable={false} />
      ) : (
        "Skills Profile"
      )}

      {editable && (
        <EditContextMenu
          onUpload={handleUpload}
          onDelete={handleDelete}
          maxSizeMB={MAX_SIZE_MB}
          hasPicture={!!pictureUrl}
          loading={loading || uploading || deleting}
        />
      )}
    </ProfilePictureContainer>
  );
}

const ProfilePictureContainer = styled.div<{ url?: string }>`
  width: 126px;
  height: 126px;
  margin-top: -68px;
  position: relative;
  border-radius: 50%;
  user-select: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: "GT Haptik";
  font-weight: 700;
  font-size: 16px;
  color: ${(props) => props.theme.colors.peach[40]};
  background-color: ${(props) => props.theme.colors.purple[100]};
`;

const Img = styled.img`
  width: 100%;
  height: 100%;
  border-radius: 50%;
  object-fit: cover;
`;
