import * as Sentry from "@sentry/nextjs";

export async function recordTransaction<T>(
  name: string,
  fn: () => Promise<T>
): Promise<T> {
  const hub = Sentry.getCurrentHub();
  const scope = hub.getScope();

  if (scope == null) {
    throw new Error("No scope available from Sentry hub");
  }

  let transaction = scope.getTransaction();

  if (transaction != null) {
    transaction.finish();
  }

  transaction = Sentry.startTransaction({ name, op: "ui.action" });
  scope.setSpan(transaction);

  try {
    return await fn();
  } finally {
    transaction.finish();
  }
}
