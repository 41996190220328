import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { faCircleInfo } from "@fortawesome/pro-solid-svg-icons";
import { device } from "@/styles/breakpoints";
import { useApiErrorHandler } from "@/features/error";
import { EditButton, Icon } from "@/features/common";
import { useProfileSkillAssessmentsQuery } from "@/generated/graphql";
import { ProfileSkillAssessmentsEditModal } from "@/features/profile";
import { EmptySkillAssessmentList, SkillAssessment } from "./ui";

interface ProfileSkillAssessmentsProps {
  slug: string;
  editable: boolean;
}

export function ProfileSkillAssessments({
  slug,
  editable,
}: ProfileSkillAssessmentsProps) {
  const handleError = useApiErrorHandler();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [{ data, error }, reexecuteQuery] = useProfileSkillAssessmentsQuery({
    variables: { slug },
  });

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  const onCloseEditModal = () => {
    reexecuteQuery({ requestPolicy: "network-only" });
    setOpenEditModal(false);
  };

  return (
    <ProfileSkillAssessmentsContainer>
      {editable && (
        <Edit>
          <EditButton onClick={() => setOpenEditModal(true)} />
          <ProfileSkillAssessmentsEditModal
            open={openEditModal}
            onClose={onCloseEditModal}
          />
        </Edit>
      )}

      <Title>Skills tests</Title>
      <Subtitle>
        All results have a Toggl Hire Guarantee.
        <SubtitleIcon>
          <Icon
            icon={faCircleInfo}
            color="orange-100"
            fontSize="8px"
            data-rh-at="top"
            data-rh="All our tests are created by subject matter experts to assess practical skills."
          />
        </SubtitleIcon>
      </Subtitle>

      {!data?.publicProfile?.skills?.length ? (
        <EmptySkillAssessmentList
          editable={editable}
          userName={data?.publicProfile?.firstName}
        />
      ) : (
        <SkillAssessmentList>
          {data?.publicProfile?.skills?.map((skill) => (
            <SkillAssessment
              key={skill._id}
              name={skill.name}
              score={skill.scoreAsPercentage}
            />
          ))}
        </SkillAssessmentList>
      )}
    </ProfileSkillAssessmentsContainer>
  );
}

const ProfileSkillAssessmentsContainer = styled.div`
  background: ${(props) => props.theme.colors.white};
  border-radius: 8px;
  padding: 24px 36px 40px 36px;
  position: relative;

  @media ${device.tablet} {
    border-radius: unset;
    padding: 16px 16px 36px 16px;
  }
`;

const Edit = styled.div`
  position: absolute;
  right: 36px;
  top: 24px;

  @media ${device.tablet} {
    right: 16px;
    top: 16px;
  }
`;

const Title = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 500;
  font-size: 40px;
  text-align: center;
  line-height: 56px;

  @media ${device.tablet} {
    font-size: 20px;
    line-height: 25px;
  }
`;

const Subtitle = styled.div`
  color: ${(props) => props.theme.typography.colorPrimary};
  font-weight: 400;
  font-size: 14px;
  text-align: center;
  line-height: 18px;
  margin-top: 4px;
  position: relative;

  @media ${device.tablet} {
    font-size: 12px;
    line-height: 15px;
    color: ${(props) => props.theme.typography.colorSecondary};
  }
`;

const SubtitleIcon = styled.span`
  position: absolute;
  top: -8px;

  @media ${device.tablet} {
    display: none;
  }
`;

const SkillAssessmentList = styled.div`
  margin-top: 74px;
  display: grid;
  gap: 24px;

  @media ${device.tablet} {
    margin-top: 24px;
    gap: 20px;
  }
`;
