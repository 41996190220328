import React, { useRef, useState } from "react";
import styled from "@emotion/styled";
import useOnClickOutside from "use-onclickoutside";
import { DSButton, DSContextMenu } from "@hundred5/design-system";
import {
  faCamera,
  faPenToSquare,
  faTrash,
} from "@fortawesome/pro-regular-svg-icons";
import {
  ALLOWED_IMAGE_FILE_TYPES,
  Icon,
  isFileSizeValid,
  useFlashMessages,
} from "@/features/common";

interface EditContextMenuProps {
  onUpload?: (file: File) => void;
  onDelete?: () => void;
  maxSizeMB: number;
  hasPicture: boolean;
  loading?: boolean;
}

export function EditContextMenu({
  onUpload,
  onDelete,
  maxSizeMB,
  hasPicture,
  loading,
}: EditContextMenuProps) {
  const { addMessage } = useFlashMessages();
  const contextMenuRef = useRef<null | HTMLDivElement>(null);
  const fileInputRef = useRef<HTMLInputElement>(null);
  const [openEditContextMenu, setOpenEditContextMenu] = useState(false);
  useOnClickOutside(contextMenuRef, () => setOpenEditContextMenu(false));

  return (
    <EditContextMenuContainer ref={contextMenuRef}>
      <DSButton
        variant="secondary"
        Icon={<Icon icon={faPenToSquare} />}
        onClick={() => setOpenEditContextMenu(!openEditContextMenu)}
        loading={loading}
      />
      <DSContextMenu
        open={openEditContextMenu}
        position={{ top: "40px", left: "0" }}
      >
        {!!onUpload && (
          <DSContextMenu.Item
            icon={<Icon icon={faCamera} />}
            label="Upload image"
            onClick={() => fileInputRef.current?.click()}
          />
        )}
        {hasPicture && !!onDelete && (
          <DSContextMenu.Item
            icon={<Icon icon={faTrash} />}
            label="Remove"
            dangerous
            onClick={() => {
              onDelete();
              setOpenEditContextMenu(false);
            }}
          />
        )}
      </DSContextMenu>

      <FileInput
        ref={fileInputRef}
        type="file"
        accept={ALLOWED_IMAGE_FILE_TYPES}
        onChange={async (event) => {
          const files = event.target.files!;

          if (files.length) {
            const file = files[0];

            if (!isFileSizeValid(file, maxSizeMB)) {
              addMessage({
                type: "upload_file_size_error",
                maxSizeMB,
              });
            } else {
              event.target.value = "";
              onUpload?.(file);
              setOpenEditContextMenu(false);
            }
          }
        }}
      />
    </EditContextMenuContainer>
  );
}

const EditContextMenuContainer = styled.div`
  position: absolute;
  top: 0;
  right: -4px;
`;

const FileInput = styled.input`
  display: none;
`;
