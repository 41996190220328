import React from "react";
import styled from "@emotion/styled";
import { device } from "@/styles/breakpoints";

export function ProfileCTA() {
  return (
    <ProfileCTAContainer>
      <Title>
        Join Toggl Hire <i>skills</i> assessment platform
      </Title>
      <CTAButtons>
        <CTAButton
          onClick={() =>
            window.open(
              "https://toggl.com/hire/free-self-assessment-tests",
              "_blank"
            )
          }
        >
          Build your skill profile
        </CTAButton>
        <CTAButton
          primary
          onClick={() =>
            window.open("https://hire.toggl.com/admin/signup", "_blank")
          }
        >
          Sign up as a company
        </CTAButton>
      </CTAButtons>
    </ProfileCTAContainer>
  );
}

const ProfileCTAContainer = styled.div`
  background-color: ${(props) => props.theme.colors.purple[90]};
  padding: 152px 36px;
  text-align: center;
  display: grid;
  gap: 60px;

  @media ${device.tablet} {
    padding: 32px 16px;
    gap: 32px;
  }
`;

const Title = styled.div`
  font-family: "GT Haptik";
  font-style: normal;
  font-weight: 500;
  font-size: 50px;
  color: ${(props) => props.theme.colors.white};
  line-height: 1.2;

  @media ${device.tablet} {
    font-size: 22px;
  }
`;

const CTAButtons = styled.div`
  display: flex;
  justify-content: center;
  flex-flow: wrap;
  gap: 32px;

  @media ${device.tablet} {
    gap: 24px;
  }
`;

const CTAButton = styled.button<{ primary?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  -webkit-appearance: none;
  cursor: pointer;
  outline: none;
  border: none;
  padding: 16px 44px;
  border-radius: 32px;
  background-color: ${(props) =>
    props.primary ? props.theme.colors.orange[100] : props.theme.colors.white};
  color: ${(props) =>
    props.primary ? props.theme.colors.white : props.theme.colors.purple[100]};
  font-family: "GT Haptik";
  font-size: 17px;
  font-weight: 500;
  text-transform: capitalize;
  transition: font-style 0.1s;

  &:hover {
    font-style: italic;
  }

  @media ${device.tablet} {
    padding: 12px 24px;
  }

  @media ${device.mobile} {
    width: 100%;
  }
`;
