import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { useApiErrorHandler } from "@/features/error";
import { EditButton, Icon } from "@/features/common";
import { faGithubSquare, faLinkedin } from "@fortawesome/free-brands-svg-icons";
import { device } from "@/styles/breakpoints";
import { useProfileGeneralDetailsQuery } from "@/generated/graphql";
import {
  ProfileGeneralDetailsEditModal,
  ProfilePicture,
} from "@/features/profile";
import illustrationBackground from "./ui/illustrationBackground.svg";
import { anonymiseCandidateName } from "./profile-general-details.utils";

interface ProfileGeneralDetailsProps {
  slug: string;
  editable: boolean;
}

export function ProfileGeneralDetails({
  slug,
  editable,
}: ProfileGeneralDetailsProps) {
  const handleError = useApiErrorHandler();
  const [openEditModal, setOpenEditModal] = useState(false);
  const [{ data, error }, reexecuteQuery] = useProfileGeneralDetailsQuery({
    variables: { slug },
  });

  const onEditModalClose = () => {
    reexecuteQuery({ requestPolicy: "network-only" });
    setOpenEditModal(false);
  };

  const publicProfileData = data?.publicProfile;

  useEffect(() => {
    if (!error) return;
    handleError(error);
  }, [handleError, error]);

  return (
    <GeneralDetailsContainer>
      <GeneralDetailsBackground url={illustrationBackground?.src} />
      <GeneralDetailsContent>
        <GeneralDetailsHeader>
          <ProfilePicture slug={slug} editable={editable} />
          {editable && (
            <Edit>
              <EditButton onClick={() => setOpenEditModal(true)} />
              <ProfileGeneralDetailsEditModal
                open={openEditModal}
                onClose={onEditModalClose}
              />
            </Edit>
          )}
        </GeneralDetailsHeader>
        <Content>
          <PersonalContent data-recording-sensitive>
            {(!!publicProfileData?.firstName ||
              !!publicProfileData?.lastName) && (
              <Title>
                {editable
                  ? `${publicProfileData?.firstName} ${publicProfileData?.lastName}`
                  : anonymiseCandidateName(
                      publicProfileData.firstName,
                      publicProfileData.lastName
                    )}
              </Title>
            )}

            {editable &&
              (!!publicProfileData?.city || !!publicProfileData?.country) && (
                <Text primary={false}>
                  {publicProfileData?.city}
                  {!!publicProfileData?.country &&
                    !!publicProfileData?.city &&
                    `, `}
                  {publicProfileData?.country}
                </Text>
              )}

            {editable && !!publicProfileData?.email && (
              <Text primary={true}>{publicProfileData?.email}</Text>
            )}

            {editable && !!publicProfileData?.phoneNumber && (
              <Text primary={true}>{publicProfileData?.phoneNumber}</Text>
            )}
          </PersonalContent>
          <SocialContent>
            {editable && !!publicProfileData?.linkedinURL && (
              <SocialContentItem
                onClick={() =>
                  window.open(publicProfileData?.linkedinURL!, "_blank")
                }
              >
                <SocialIcon icon={faLinkedin as any} />
                <SocialContentText>LinkedIn</SocialContentText>
              </SocialContentItem>
            )}

            {editable && !!publicProfileData?.githubURL && (
              <SocialContentItem
                onClick={() =>
                  window.open(publicProfileData?.githubURL!, "_blank")
                }
              >
                <SocialIcon icon={faGithubSquare as any} />
                <SocialContentText>GitHub</SocialContentText>
              </SocialContentItem>
            )}
          </SocialContent>
        </Content>
      </GeneralDetailsContent>
    </GeneralDetailsContainer>
  );
}

const GeneralDetailsContainer = styled.div`
  border-radius: 8px;
  overflow: hidden;

  @media ${device.tablet} {
    border-radius: unset;
  }
`;

const GeneralDetailsBackground = styled.div<{ url: string }>`
  width: 100%;
  height: 140px;
  background: ${(props) => props.theme.colors.peach[100]};
  background-image: ${(props) => props.url && `url(${props.url})`};
  background-repeat: no-repeat;
  background-position: bottom;
  background-size: cover;

  @media ${device.tablet} {
    height: 126px;
  }
`;

const GeneralDetailsContent = styled.div`
  padding: 0 36px 36px 36px;
  background: ${(props) => props.theme.colors.white};

  @media ${device.tablet} {
    padding: 0 16px 16px 16px;
  }
`;

const GeneralDetailsHeader = styled.div`
  display: flex;
  width: 100%;
  flex-direction: row;
  align-items: end;
`;

const Edit = styled.div`
  margin-left: auto;
  self-align: self-end;
`;

const Content = styled.div`
  display: flex;
  flex-direction: row;
  padding-top: 14px;

  @media ${device.tablet} {
    flex-direction: column;
  }
`;

const PersonalContent = styled.div`
  width: 65%;
  display: flex;
  flex-direction: column;
  gap: 14px;

  @media ${device.tablet} {
    width: 100%;
    gap: 12px;
  }
`;

const Title = styled.div`
  font-size: 22px;
  font-weight: 700;
`;

const Text = styled.div<{ primary?: boolean }>`
  font-size: 14px;
  font-weight: 500;
  color: ${(props) =>
    props.primary
      ? props.theme.typography.colorPrimary
      : props.theme.typography.colorSecondary};
`;

const SocialContent = styled.div`
  width: 35%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 14px;

  @media ${device.tablet} {
    width: 100%;
    margin-top: 26px;
    gap: 16px;
  }
`;

const SocialContentItem = styled.a`
  width: 120px;
  display: flex;
  flex-direcetion: row;
  align-items: center;
  color: ${(props) => props.theme.colors.purple[100]};
`;

const SocialIcon = styled(Icon)`
  font-size: 32px;

  @media ${device.tablet} {
    font-size: 22px;
  }
`;

const SocialContentText = styled.div`
  font-size: 14px;
  font-weight: 500;
  margin-left: 12px;

  @media ${device.tablet} {
    margin-left: 8px;
  }
`;
