import React, { useEffect } from "react";
import Head from "next/head";
import { useRouter } from "next/router";
import { useAmplitude } from "@/features/analytics";
import { Loader, PageTitle } from "@/features/common";
import {
  Profile,
  ProfileNavigationHeader,
  useValidateProfile,
} from "@/features/profile";
import { ErrorPage } from "@/features/error";
import { LockedPage } from "@/features/auth";

export default function ProfilePage() {
  const router = useRouter();
  const { id } = router.query as { id: string };
  const { logEvent, logPublicEvent } = useAmplitude();
  const { loading, exists, isMine } = useValidateProfile(id);

  useEffect(() => {
    if (!loading && exists) {
      if (isMine) {
        logEvent("page view/profile");
      } else {
        logPublicEvent("page view/public profile", {
          slug: id,
        });
      }
    }
  }, [logEvent, logPublicEvent, id, loading, exists, isMine]);

  if (loading) {
    return <Loader absolute />;
  }

  if (!exists) {
    return <ErrorPage message="Sorry, this page does not exist." />;
  }

  return isMine ? (
    <LockedPage>
      <PageTitle title="My Profile" />
      <ProfileNavigationHeader slug={id} />
      <Profile slug={id} editable={true} />
    </LockedPage>
  ) : (
    <>
      <Head>
        <meta name="robots" content="noindex" />
      </Head>
      <PageTitle title="Public Profile" />
      <Profile slug={id} editable={false} />
    </>
  );
}
