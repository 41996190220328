import React, { useRef, useState } from "react";
import { Icon, useFlashMessages } from "@/features/common";
import { useAmplitude } from "@/features/analytics";
import { DSContextMenu, DSContextMenuButton } from "@hundred5/design-system";
import useOnClickOutside from "use-onclickoutside";
import { ConfirmModal } from "../../../common/components/confirm-modal";
import { useDeleteAccountMutation } from "@/generated/graphql";
import { useApiErrorHandler } from "@/features/error";
import { faTrash } from "@fortawesome/pro-regular-svg-icons";
import { useLogout } from "@/features/auth";

interface ProfileNavigationHeaderContextMenuProps {}

export function ProfileNavigationHeaderContextMenu({}: ProfileNavigationHeaderContextMenuProps) {
  const { logEvent } = useAmplitude();
  const menuRef = useRef<null | HTMLDivElement>(null);
  const [contextMenuOpen, setContextMenuOpen] = useState(false);
  const [openConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [{}, deleteAccount] = useDeleteAccountMutation();
  const handleError = useApiErrorHandler();
  const { addMessage } = useFlashMessages();
  const logOut = useLogout();

  useOnClickOutside(menuRef, () => setContextMenuOpen(false));

  const handleDelete = async () => {
    const result = await deleteAccount({});

    if (result.error) {
      handleError(result.error);
    } else {
      addMessage({ type: "save_success" });
      logEvent("profile/delete");

      logOut();
    }
  };

  return (
    <div ref={menuRef}>
      <DSContextMenuButton onClick={() => setContextMenuOpen(true)} />
      <DSContextMenu open={contextMenuOpen} position={{ right: "8px" }}>
        <DSContextMenu.Item
          label="Delete account"
          icon={<Icon icon={faTrash} color="purple-100" fontSize="12px" />}
          onClick={() => {
            setOpenConfirmModal(true);
            setContextMenuOpen(false);
          }}
          dangerous
        />
      </DSContextMenu>
      <ConfirmModal
        open={openConfirmModal}
        action="Delete my account"
        onConfirm={() => {
          handleDelete();
          setOpenConfirmModal(false);
        }}
        confirmation="delete"
        onClose={() => setOpenConfirmModal(false)}
        title="Delete account"
        footerVariant="destructive-primary"
      >
        <p>Are you sure you want to delete your account?</p>
        <p>
          Deleting your account is permanent and cannot be undone. Please note
          that this will not withdraw your application from any jobs you have
          applied to.
        </p>
      </ConfirmModal>
    </div>
  );
}
